import { DqCard, DqCardContent } from "@decentriq/components";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { Typography } from "@mui/joy";
import { format } from "date-fns";
import { Fragment, memo } from "react";
import { DetailsGridValue, useDataRoomDrawerContext } from "components";
import { DataRoomTypeNames } from "models";

const DataRoomDateInfo = memo(() => {
  const { dataRoomOverviewData, showDetailedDateInfo, __typename } =
    useDataRoomDrawerContext();

  const createdAt = dataRoomOverviewData?.createdAt;
  const updatedAt = dataRoomOverviewData?.updatedAt;
  const activatedAt = dataRoomOverviewData?.activatedAt;
  const deactivatedAt = dataRoomOverviewData?.deactivatedAt;
  const stoppedAt = dataRoomOverviewData?.stoppedAt;

  if (!showDetailedDateInfo) {
    return (
      <DqCard>
        <DqCardContent sx={{ alignItems: "flex-start" }}>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            <strong>{`${
              __typename === DataRoomTypeNames.DraftDataRoom
                ? "Creation"
                : "Publication"
            } date`}</strong>
          </Typography>
          {createdAt ? (
            <DetailsGridValue
              icon={faClock}
              value={format(new Date(createdAt), "dd.MM.yyy, HH:mm")}
            />
          ) : (
            "—"
          )}
        </DqCardContent>
      </DqCard>
    );
  }

  return (
    <Fragment>
      <DqCard>
        <DqCardContent sx={{ alignItems: "flex-start" }}>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            <strong>Created at:</strong>
          </Typography>
          {createdAt ? format(new Date(createdAt), "dd.MM.yyy, HH:mm") : "—"}
        </DqCardContent>
      </DqCard>
      <DqCard>
        <DqCardContent sx={{ alignItems: "flex-start" }}>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            <strong>Updated at:</strong>
          </Typography>
          {updatedAt ? format(new Date(updatedAt), "dd.MM.yyy, HH:mm") : "—"}
        </DqCardContent>
      </DqCard>
      <DqCard>
        <DqCardContent sx={{ alignItems: "flex-start" }}>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            <strong>Activated at:</strong>
          </Typography>
          {activatedAt
            ? format(new Date(activatedAt), "dd.MM.yyy, HH:mm")
            : "—"}
        </DqCardContent>
      </DqCard>
      <DqCard>
        <DqCardContent sx={{ alignItems: "flex-start" }}>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            <strong>Deactivated at:</strong>
          </Typography>
          {deactivatedAt
            ? format(new Date(deactivatedAt), "dd.MM.yyy, HH:mm")
            : "—"}
        </DqCardContent>
      </DqCard>
      <DqCard>
        <DqCardContent sx={{ alignItems: "flex-start" }}>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            <strong>Stopped at</strong>
          </Typography>
          {stoppedAt ? format(new Date(stoppedAt), "dd.MM.yyy, HH:mm") : "—"}
        </DqCardContent>
      </DqCard>
    </Fragment>
  );
});

DataRoomDateInfo.displayName = "DataRoomDateInfo";

export default DataRoomDateInfo;
