import { DqCard, DqCardContent } from "@decentriq/components";
import { faDiagramProject } from "@fortawesome/pro-light-svg-icons";
import { Typography } from "@mui/joy";
import { memo } from "react";
import { useDataRoomDrawerContext } from "components";
import { DetailsGridValue } from "components/base";

const DataRoomCollaboratingOrganizations = memo(() => {
  const { dataRoomOverviewData } = useDataRoomDrawerContext();

  const collaboratingOrganizationNames =
    dataRoomOverviewData?.collaboratingOrganizationNames || [];

  return (
    <DqCard>
      <DqCardContent sx={{ alignItems: "flex-start" }}>
        <Typography gutterBottom={true} level="body-sm" textColor="inherit">
          <strong>Collaborating organizations</strong>
        </Typography>
        {collaboratingOrganizationNames.length ? (
          <DetailsGridValue
            icon={faDiagramProject}
            value={collaboratingOrganizationNames.join(", ")}
          />
        ) : (
          <span>—</span>
        )}
      </DqCardContent>
    </DqCard>
  );
});

DataRoomCollaboratingOrganizations.displayName =
  "DataRoomCollaboratingOrganizations";

export default DataRoomCollaboratingOrganizations;
