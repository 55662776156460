import {
  dataSourceTypePresentation,
  dataTargetTypePresentation,
  DqEmptyData,
  DqLoader,
  DqTable,
} from "@decentriq/components";
import { useDataConnectorJobsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type DataConnectorJob,
  DataConnectorJobType,
  DataImportExportStatus,
  type DataSourceType,
  type DataTargetType,
} from "@decentriq/graphql/dist/types";
import { faFileExport, faFileImport } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography } from "@mui/joy";
import { orderBy } from "lodash";
import { type MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { TimeAgoFormatted } from "components";
import {
  ExternalConnectionDrawer,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import { externalConnectionStatusPresentation } from "features/datasets/components/ExternalConnectionDetails/ExternalConnectionDetails";

const externalsColumnDef: MRT_ColumnDef<DataConnectorJob>[] = [
  {
    Cell: ({ cell }) => {
      const name = cell.getValue<string>();
      return (
        <Typography level="body-sm" textColor="inherit">
          {name}
        </Typography>
      );
    },
    accessorKey: "name",
    enableSorting: false,
    header: "Name",
    id: "name",
  },
  {
    Cell: ({ cell, row }) => {
      const status = cell.getValue<DataImportExportStatus>();
      return (
        <Typography
          level="body-sm"
          sx={{ cursor: "pointer" }}
          textColor={
            status === DataImportExportStatus.Failed ? "danger" : "inherit"
          }
        >
          {status === DataImportExportStatus.Success ? (
            <>
              Completed{" "}
              {!!row.original.finishedAt && (
                <TimeAgoFormatted date={row.original.finishedAt} />
              )}
            </>
          ) : (
            externalConnectionStatusPresentation.get(status)
          )}
        </Typography>
      );
    },
    accessorKey: "status",
    header: "Status",
    id: "status",
  },
  {
    Cell: ({ cell }) => {
      const createdAt = cell.getValue<string>();
      return (
        <Typography level="body-sm" textColor="inherit">
          <TimeAgoFormatted date={createdAt} />
        </Typography>
      );
    },
    accessorKey: "createdAt",
    header: "Started at",
    id: "createdAt",
  },
  {
    Cell: ({ cell, row }) => {
      const sourceType = cell.getValue<DataSourceType>();
      const isExport = [
        DataConnectorJobType.ExportDataset,
        DataConnectorJobType.ExportDcrJobResult,
      ].includes(row.original.type);
      const connectionType = isExport
        ? row.original.targetType!
        : row.original.sourceType!;
      return (
        <Typography
          level="body-sm"
          startDecorator={
            <ExternalConnectionsIcon
              connectionType={connectionType}
              size={ExternalConnectionsIconSize.xs}
            />
          }
          textColor="inherit"
        >
          {isExport
            ? dataTargetTypePresentation[connectionType as DataTargetType]
            : dataSourceTypePresentation[sourceType]}
        </Typography>
      );
    },
    accessorKey: "sourceType",
    header: "Imported from",
    id: "sourceType",
  },
  {
    Cell: ({ cell }) => {
      const type = cell.getValue<DataConnectorJobType>();
      const isExport = [
        DataConnectorJobType.ExportDataset,
        DataConnectorJobType.ExportDcrJobResult,
      ].includes(type);
      return (
        <Typography
          level="body-sm"
          startDecorator={
            <FontAwesomeIcon
              fixedWidth={true}
              icon={isExport ? faFileExport : faFileImport}
            />
          }
          textColor="inherit"
        >
          {isExport ? "Export" : "Import"}
        </Typography>
      );
    },
    accessorKey: "type",
    header: "Type",
    id: "type",
  },
];

const ExternalConnectionsList: React.FC = () => {
  const [sortDir] = useState<"asc" | "desc">("desc");
  const timeFilter = useMemo(
    () => ({
      createdBefore: new Date().toISOString(),
    }),
    []
  );
  const externalsQueryFilter = useMemo(() => ({ ...timeFilter }), [timeFilter]);
  const { data, loading } = useDataConnectorJobsQuery({
    variables: {
      filter: externalsQueryFilter,
    },
  });
  const externals = useMemo(() => {
    const connectionsArray = data?.dataConnectorJobs?.nodes;
    return orderBy(
      connectionsArray,
      ({ createdAt }) => new Date(createdAt),
      sortDir
    );
  }, [data, sortDir]);
  const [selectedConnectionId, setSelectedConnectionId] = useState<
    string | null
  >(null);

  const totalCount = externals.length;
  if (loading) {
    return (
      <Stack alignItems="center" height="100%" justifyContent="center">
        <DqLoader size="sm" />
      </Stack>
    );
  }
  if (!loading && !totalCount) {
    return (
      <DqEmptyData
        description="To export an existing dataset, go to the Datasets tab, select a dataset and press the Export button."
        title="To add a dataset, click the button in the top-right corner. To download a dataset, go to the Datasets tab, select it, and click Export."
      />
    );
  }
  return (
    <Box
      sx={{
        alignItems: "stretch",
        backgroundColor: "common.white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "stretch",
        overflow: "hidden",
      }}
    >
      <DqTable
        columns={externalsColumnDef}
        data={externals}
        enableBatchRowSelection={false}
        enableMultiRowSelection={false}
        enableRowActions={true}
        enableRowSelection={true}
        enableSelectAll={false}
        getRowId={(row) => row.id}
        localization={{
          noRecordsToDisplay: "No imports/exports found",
        }}
        muiTableBodyRowProps={({
          row: {
            original: { id },
            getToggleSelectedHandler,
          },
        }) => {
          return {
            onClick: () => {
              getToggleSelectedHandler();
              setSelectedConnectionId(id);
            },
            sx: {
              "& > .MuiTableCell-root:first-child": { pl: 2 },
              "& > .MuiTableCell-root:last-child": { pr: 2 },
              cursor: "pointer",
            },
          };
        }}
        muiTableHeadRowProps={{
          sx: {
            "& > .MuiTableCell-root:first-child": { pl: 2 },
          },
        }}
        muiTablePaperProps={{
          sx: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "hidden",
            width: "100%",
          },
        }}
        state={{
          columnVisibility: { "mrt-row-select": false },
          rowSelection: selectedConnectionId
            ? { [selectedConnectionId]: true }
            : {},
        }}
      />
      <ExternalConnectionDrawer
        connectionId={selectedConnectionId}
        onClose={() => setSelectedConnectionId(null)}
        open={Boolean(selectedConnectionId)}
      />
    </Box>
  );
};

export default ExternalConnectionsList;
