import { useCallback } from "react";
import { useDraftDataRoomIsFavorite } from "features/draftDataRoom/components/DraftDataRoomActions/hooks";
import { usePublishedDataRoomIsFavorite } from "features/publishedDataRoom/components/PublishedDataRoomActions/hooks";
import { DataRoomTypeNames } from "models";
import { useDataRoomDrawerContext } from "../contexts";

export const useDataRoomToggleFavorite = () => {
  const { __typename, dataRoomId } = useDataRoomDrawerContext();

  const { setDraftDataRoomIsFavorite } = useDraftDataRoomIsFavorite({
    id: dataRoomId,
    skip: __typename !== DataRoomTypeNames.DraftDataRoom,
  });
  const { setPublishedDataRoomIsFavorite } = usePublishedDataRoomIsFavorite({
    __typename: DataRoomTypeNames.PublishedDataRoom,
    id: dataRoomId,
    skip: __typename === DataRoomTypeNames.DraftDataRoom,
  });
  const toggleFavorite = useCallback(
    (isFavorite: boolean) => {
      if (dataRoomId) {
        switch (__typename) {
          case DataRoomTypeNames.DraftDataRoom:
            return setDraftDataRoomIsFavorite(isFavorite);
          case DataRoomTypeNames.PublishedDataRoom:
          case DataRoomTypeNames.PublishedMediaInsightsDcr:
            return setPublishedDataRoomIsFavorite(isFavorite);
          default:
            return null;
        }
      } else {
        return null;
      }
    },
    [
      __typename,
      dataRoomId,
      setDraftDataRoomIsFavorite,
      setPublishedDataRoomIsFavorite,
    ]
  );

  return { toggleFavorite };
};
