import { DqSidePanelTitle } from "@decentriq/components";
import { faStar as falStar } from "@fortawesome/pro-light-svg-icons";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Tooltip } from "@mui/joy";
import { Fragment, memo } from "react";
import { useToggleFavorite } from "components";
import { DataRoomActions, DataRoomActionTypes } from "features";
import { useDataRoomDrawerContext } from "../../contexts";

interface DataRoomToolbarProps {
  onClose: () => void;
}

const DataRoomToolbar = memo<DataRoomToolbarProps>(({ onClose }) => {
  const { name, isFavorite, dataRoomId, showToolbarActions, __typename } =
    useDataRoomDrawerContext();
  const { toggleFavorite } = useToggleFavorite();

  const dataRoomToolbarActions = ({
    buttons,
    menuLists,
  }: {
    buttons: any[];
    menuLists: any[];
  }) => ({
    buttons: [
      ...buttons.filter(
        ({ type }: any) =>
          ![
            DataRoomActionTypes.Publish,
            DataRoomActionTypes.ToggleFavorite,
          ].includes(type)
      ),
    ],
    menuLists,
  });
  return (
    <DqSidePanelTitle onClose={onClose} separated={true}>
      <Box
        sx={{
          alignItems: "baseline",
          display: "flex",
          flex: 1,
          gap: 1,
        }}
      >
        {name}
        {showToolbarActions && (
          <Fragment>
            <Tooltip
              title={`${isFavorite ? "Remove from" : "Add to"} favorites`}
            >
              <IconButton onClick={() => void toggleFavorite(!isFavorite)}>
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={isFavorite ? fasStar : falStar}
                />
              </IconButton>
            </Tooltip>

            {/* The component here is used instead of ToolbarProps for DqSidePanelTitle in order 
        to unify DCRs actions around the platform and avoid duplicating logic */}
            <Box marginLeft="auto">
              <DataRoomActions
                __typename={__typename}
                actions={dataRoomToolbarActions}
                id={dataRoomId}
              />
            </Box>
          </Fragment>
        )}
      </Box>
    </DqSidePanelTitle>
  );
});

DataRoomToolbar.displayName = "DataRoomToolbar";

export default DataRoomToolbar;
