import { createContext, memo, type PropsWithChildren, useMemo } from "react";
import { DataRoomDrawerVariant } from "components";
import { useDataRoomOverviewData, useSafeContext, useUserRole } from "hooks";
import { type DataRoomOverview } from "hooks/useDataRoomOverviewData";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";

interface DataRoomDrawerContextProps {
  __typename: DataRoomTypeNames;
  dataRoomId: string;
  onClose: () => void;
  variant: DataRoomDrawerVariant;
}
interface DataRoomDrawerContextType {
  __typename: DataRoomTypeNames;
  dataRoomId: string;
  dataRoomLink: string;
  dataRoomOverviewData: DataRoomOverview | null;
  isFavorite: boolean;
  loading: boolean;
  name: string;
  onClose: () => void;
  showCreatorOrganization: boolean;
  showDatalabInfo: boolean;
  showDcrFeatures: boolean;
  showDeactivateButton: boolean;
  showDetailedDateInfo: boolean;
  showDetailedParticipantsInfo: boolean;
  showKind: boolean;
  showOpenButton: boolean;
  showToolbarActions: boolean;
}

const DataRoomDrawerContext = createContext<DataRoomDrawerContextType | null>(
  null
);

export const useDataRoomDrawerContext = () =>
  useSafeContext(DataRoomDrawerContext);

export const DataRoomDrawerProvider = memo<
  PropsWithChildren<DataRoomDrawerContextProps>
>(({ children, dataRoomId, __typename, variant, onClose }) => {
  const { data: overviewData, isLoading } = useDataRoomOverviewData({
    dataRoomId: dataRoomId,
    typename: __typename,
  });

  const { isSuperAdmin, isDecentriqAdmin } = useUserRole();

  const showDeactivateButton = useMemo(
    () =>
      variant === DataRoomDrawerVariant.Admin &&
      isSuperAdmin &&
      (overviewData?.isActive ?? false),
    [variant, isSuperAdmin, overviewData?.isActive]
  );

  const dataRoomLink = useMemo(
    () => dataRoomPathBuilder(dataRoomId, __typename),
    [dataRoomId, __typename]
  );

  const isMediaDcr = [
    DataRoomTypeNames.PublishedMediaDataRoom,
    DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
    DataRoomTypeNames.PublishedMediaInsightsDcr,
  ].includes(__typename);

  const contextValue: DataRoomDrawerContextType = useMemo(() => {
    return {
      __typename,
      dataRoomId,
      dataRoomLink,
      dataRoomOverviewData: overviewData,
      isFavorite: overviewData?.isFavorite ?? false,
      loading: isLoading,
      name: overviewData?.name ?? "",
      onClose,
      showCreatorOrganization:
        variant === DataRoomDrawerVariant.Admin && isDecentriqAdmin,
      showDatalabInfo:
        variant === DataRoomDrawerVariant.Admin &&
        isDecentriqAdmin &&
        isMediaDcr,
      showDcrFeatures: isMediaDcr,
      showDeactivateButton,
      showDetailedDateInfo: variant === DataRoomDrawerVariant.Admin,
      showDetailedParticipantsInfo: variant === DataRoomDrawerVariant.Admin,
      showKind: variant === DataRoomDrawerVariant.Admin && isDecentriqAdmin,
      showOpenButton: variant === DataRoomDrawerVariant.DcrsList,
      showToolbarActions: variant === DataRoomDrawerVariant.DcrsList,
    };
  }, [
    __typename,
    dataRoomId,
    dataRoomLink,
    overviewData,
    isLoading,
    onClose,
    variant,
    isDecentriqAdmin,
    isMediaDcr,
    showDeactivateButton,
  ]);
  return (
    <DataRoomDrawerContext.Provider value={contextValue}>
      {children}
    </DataRoomDrawerContext.Provider>
  );
});
