import { DqCard, DqCardContent } from "@decentriq/components";
import { Box, Typography } from "@mui/joy";
import { memo } from "react";
import { useDataRoomDrawerContext } from "components";
import { StateIcon } from "components/base";
import { DataRoomTypeNames } from "models";

const DataRoomStatus = memo(() => {
  const { dataRoomOverviewData, __typename } = useDataRoomDrawerContext();

  const isLocked =
    __typename === DataRoomTypeNames.PublishedDataRoom ||
    __typename === DataRoomTypeNames.PublishedMediaDataRoom ||
    __typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom ||
    __typename === DataRoomTypeNames.PublishedMediaInsightsDcr;

  const isStopped = dataRoomOverviewData?.isStopped;

  return (
    <DqCard>
      <DqCardContent sx={{ alignItems: "flex-start" }}>
        <Typography gutterBottom={true} level="body-sm" textColor="inherit">
          <strong>Status</strong>
        </Typography>
        <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
          <StateIcon
            status={isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"}
          />
          <Typography level="body-sm">
            {isLocked ? (isStopped ? "Stopped" : "Published") : "Draft"}
          </Typography>
        </Box>
      </DqCardContent>
    </DqCard>
  );
});
DataRoomStatus.displayName = "DataRoomStatus";

export default DataRoomStatus;
