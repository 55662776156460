import { DqSidePanel, DqSidePanelContent } from "@decentriq/components";
import { memo } from "react";
import { DataRoomDrawerProvider } from "components";
import { type DataRoomTypeNames } from "models";
import {
  DataRoomActions,
  DataRoomInformation,
  DataRoomToolbar,
} from "./components";
import { type DataRoomDrawerVariant } from "./model";

interface DataRoomDrawerProps {
  __typename?: DataRoomTypeNames;
  variant: DataRoomDrawerVariant;
  dataRoomId?: string;
  open: boolean;
  onClose: () => void;
}

const DataRoomDrawer = memo<DataRoomDrawerProps>(
  ({ __typename, dataRoomId, variant, open, onClose }) => {
    return (
      <DqSidePanel onClose={onClose} open={open}>
        {dataRoomId && __typename ? (
          <DataRoomDrawerProvider
            __typename={__typename}
            dataRoomId={dataRoomId}
            onClose={onClose}
            variant={variant}
          >
            <DataRoomToolbar onClose={onClose} />
            <DqSidePanelContent>
              <DataRoomInformation />
            </DqSidePanelContent>
            <DataRoomActions />
          </DataRoomDrawerProvider>
        ) : null}
      </DqSidePanel>
    );
  }
);

DataRoomDrawer.displayName = "DataRoomDrawer";

export default DataRoomDrawer;
