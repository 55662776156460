import { DqSidePanelActions } from "@decentriq/components";
import { faArrowRight as falArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { DeactivateDataRoomButton } from "components/entities/organization";
import { useDataRoomDrawerContext } from "../../contexts";

const DataRoomActions = memo(() => {
  const navigate = useNavigate();
  const {
    dataRoomId,
    dataRoomLink,
    onClose,
    showDeactivateButton,
    showOpenButton,
  } = useDataRoomDrawerContext();

  if (!(showDeactivateButton || showOpenButton)) {
    return null;
  }

  return (
    <DqSidePanelActions>
      {showOpenButton && (
        <Button
          color="primary"
          endDecorator={
            <FontAwesomeIcon fixedWidth={true} icon={falArrowRight} />
          }
          onClick={() => navigate(dataRoomLink, { replace: false })}
          variant="solid"
        >
          Open
        </Button>
      )}
      {showDeactivateButton && (
        <DeactivateDataRoomButton
          closeDataRoomDetailsDialog={onClose}
          dataRoomId={dataRoomId}
        />
      )}
    </DqSidePanelActions>
  );
});

DataRoomActions.displayName = "DataRoomActions";

export default DataRoomActions;
